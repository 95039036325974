import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Col,
  Row
} from "@jarvis-catalyst/ui-components";
import React, { useState } from "react";
import Swal from "sweetalert2";
import LoadingComponent from "../../../components/Loading";
import { ButtonBackCustom } from "../../../styled-components/Buttons";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ClaimsUser, ClientUnlockLocationState, UserSelectedLockBlock } from "../../../types";
import { useQueryClaims } from "../../../hooks/useClaims";
import { useClientsLockUnlock } from "../../../hooks/useClientLockUnlock";
import { FormAlertGroup } from "../../../styled-components/FormGroup";
import ErrorComponent from "../../../components/Error";

const ClientUnlockAction = (): React.ReactElement => {
  const [selected, setSelected] = useState<UserSelectedLockBlock[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { form } = location.state as ClientUnlockLocationState;
  const { useMutationLockUnlock } = useClientsLockUnlock();
  const { claimsUseQuery, filterAccounts, invalidateKey } = useQueryClaims({
    serviceProvider: form.serviceProvider,
    documentNumber: form.nroDocumento,
    email: form.email
  });

  const addSelected = (account: ClaimsUser) => {
    const lastSelected = [...selected];
    lastSelected.push({
      ciamId: account.ciamId,
      userId: account.id,
      userName: account.userName,
      serviceProviderName: account.serviceProvider,
      enabled: account.enabled
    });
    setSelected(lastSelected);
  };

  const removeSelected = (account: ClaimsUser) => {
    const lastSelected = [...selected];
    const index = lastSelected.findIndex((item) => item.userId === account.id);
    lastSelected.splice(index, 1);
    setSelected(lastSelected);
  };

  return (
    <div>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={12} lg={12} xs={12} sm={12}>
          <div
            style={{
              maxWidth: "150px"
            }}
          >
            <div className="mt-2 mb-2">
              <ButtonBackCustom onClick={() => navigate("/user-management")}>Volver</ButtonBackCustom>
            </div>
          </div>
          <Card className="shadow table-responsive">
            <h2 className="fw-bold mb-2 text-uppercase text-center">Desbloquear Cuentas</h2>
            <hr />

            {claimsUseQuery.isLoading ? (
              <LoadingComponent />
            ) : (
              <>
                {claimsUseQuery.isError ? (
                  <>
                    {" "}
                    <ErrorComponent
                      isError={claimsUseQuery.error.statusCode}
                      title={claimsUseQuery.error.message}
                    />
                  </>
                ) : (
                  <>
                    <CardBody>
                      <table className="table table-bordered">
                        <thead>
                          <tr className="text-uppercase text-center">
                            <th scope="col">#</th>
                            <th scope="col">Cuenta</th>
                            <th scope="col">CIAM ID</th>
                            <th scope="colspan">Estado</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterAccounts?.map((account) => {
                            const badgeColor = account.enabled ? "success" : "danger";
                            const badgeText = account.enabled ? "Activo" : "Inactivo";

                            return (
                              account.ciamId && (
                                <tr className="text-center" key={account.ciamId}>
                                  <th scope="row">{account.id}</th>
                                  <td>{account.userName}</td>
                                  <td>{account.ciamId}</td>
                                  <td>
                                    <Badge color={badgeColor}>{badgeText}</Badge>
                                  </td>
                                  <td colSpan={2} className="text-center">
                                    <Checkbox
                                      value={selected.some((item) => item.ciamId === account.ciamId)}
                                      onChange={(value) => {
                                        if (value) {
                                          addSelected(account);
                                        } else {
                                          removeSelected(account);
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter>
                      <div className="d-grid">
                        <FormAlertGroup className="text-center">
                          <Alert color="info">
                            <strong>Importante:</strong> Debes seleccionar al menos una cuenta para habilitar
                            el administrador de cuentas.
                          </Alert>
                        </FormAlertGroup>
                        {selected.length > 0 && (
                          <Button
                            className="mt-2 text-center"
                            children="Administrar Seleccionados"
                            color="primary"
                            onClick={() => {
                              Swal.fire({
                                title: "Aquí podrás administrar el estado de la cuenta.",
                                text: "Las cuenta seleccionadas podrán ser 'Activadas' o 'Desactivadas'.",
                                icon: "warning",
                                input: "select",
                                inputOptions: {
                                  unlock: "Activar",
                                  lock: "Desactivar"
                                },
                                showCancelButton: true,
                                confirmButtonColor: "#5266ff",
                                cancelButtonColor: "#d33",
                                showLoaderOnConfirm: false,
                                preConfirm: async (selectedValue) => {
                                  if (!selectedValue) {
                                    return Swal.showValidationMessage("Debes seleccionar una opción");
                                  }

                                  const booleanLock = selectedValue === "lock" ? false : true;

                                  const filterSelected = selected.filter(
                                    (item) => item.enabled !== booleanLock
                                  );

                                  if (filterSelected.length === 0) {
                                    Swal.fire({
                                      title:
                                        "Los usuarios seleccionados ya se encuentran en el estado seleccionado.",
                                      text: "Por favor, selecciona otra opción.",
                                      icon: "info",
                                      confirmButtonColor: "#5266ff"
                                    });
                                  } else {
                                    await useMutationLockUnlock.mutate({
                                      operation: selectedValue,
                                      serviceProvider: form.serviceProvider,
                                      users: filterSelected
                                    });

                                    invalidateKey();
                                    setSelected([]);
                                  }
                                }
                              });
                            }}
                          />
                        )}
                      </div>
                    </CardFooter>
                  </>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ClientUnlockAction;
