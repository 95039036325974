import React, { CSSProperties } from "react";
import { DataUser } from "../../types";
import { Text } from "@jarvis-catalyst/ui-components";
import { useUtils } from "../../hooks/useUtils";

type Props = {
  users?: DataUser[];
};

const styleMatchServiceProvider: CSSProperties = {
  borderRadius: "5px",
  padding: "10px",
  boxShadow: "0 0 10px #d33"
};

const UsersTab = ({ users }: Props): JSX.Element => {
  const { serviceProvider, userName } = useUtils();
  return (
    <>
      {users?.map((user, index) => {
        const isUserMatched =
          serviceProvider === user.serviceProvider && userName?.replace(/[-]/gi, "") === user.userName;
        return (
          <div key={index}>
            <div style={isUserMatched ? styleMatchServiceProvider : {}}>
              <div className="row">
                <div className="col">
                  <Text className="">User Name</Text>
                  <input type="text" className="form-control" disabled value={user.userName} />
                </div>

                <div className="col">
                  <Text className="">Enabled</Text>
                  <input type="text" className="form-control" disabled value={String(user.enabled)} />
                </div>
              </div>
            </div>
            {users.length > 1 ? <hr /> : <br />}
          </div>
        );
      })}
    </>
  );
};

export default UsersTab;
