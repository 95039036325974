import { ResponseGetConsentProfileService, UserType } from "../types";
import axiosInstance from "../config/axiosIntance";

type KeycloakUserServiceParams = {
  username: string;
  serviceProvider: string;
  nroDocumento?: string;
  tipoDocumento?: string;
  currentUser: UserType;
  isB2B?: boolean;
};

export const getKeycloakUserService = async ({
  username,
  serviceProvider,
  currentUser
}: KeycloakUserServiceParams): Promise<ResponseGetConsentProfileService> => {
  const responseFetched = await axiosInstance.post(
    `/keycloak/user`,
    {
      username,
      serviceProvider
    },
    {
      headers: {
        Authorization: `Bearer ${currentUser.token}`
      }
    }
  );

  const { data } = responseFetched.data;

  return data;
};
