import { Dispatch, SetStateAction, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import {
  ConsentResponse,
  ProfileUsers,
  ResponseGetConsentProfileService,
  UserType,
  useQueryApiError
} from "../types";
import { getKeycloakUserService } from "../services/getKeycloakUser";

type FormType = {
  email: string;
  serviceProvider: string;
  nroDocumento?: string;
  tipoDocumento?: string;
  currentUser: UserType;
  isB2B?: boolean;
};

export const initialForm: FormType = {
  email: "",
  tipoDocumento: "",
  nroDocumento: "",
  serviceProvider: "",
  currentUser: {
    token: "",
    email: "",
    name: ""
  },
  isB2B: false
};

type ConsentHookType = {
  consentQuery: UseQueryResult<ResponseGetConsentProfileService, useQueryApiError>;
  setFetched: Dispatch<SetStateAction<boolean>>;
  getConsents?: ConsentResponse[];
  getProfile?: ProfileUsers;
};

export const useKeycloak = (form: FormType): ConsentHookType => {
  const [fetched, setFetched] = useState(false);

  const keyCache = `userinfo-${form.email}-${form.serviceProvider}`;

  const consentQuery = useQuery<ResponseGetConsentProfileService, useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getKeycloakUserService({
        username: form.email,
        serviceProvider: form.serviceProvider,
        currentUser: form.currentUser
      });
      setFetched(false);
      return response;
    },
    { enabled: fetched, cacheTime: 500 }
  );

  return {
    consentQuery,
    setFetched,
    getConsents: consentQuery.data?.consents,
    getProfile: consentQuery.data?.customer
  };
};
