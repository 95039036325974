import { Button, Card, CardBody, CardHeader, Col, Input, Row, Text, Switch } from "@jarvis-catalyst/ui-components";
import React, { ReactElement, useState, useReducer } from "react";
import { CardBodyCustom } from "../../../styled-components/Card";
import { ConsentStyledForm } from "../../../styled-components/ConsentForm";
import { useForm } from "react-hook-form";
import UpdateConsentComponent from "./updateConsent";

import { useUser } from "../../../hooks/useUser";
import { NewVersionConsentConfigType } from "../../../types/newVersionConsentConfig";

import { useUpdateTemplate } from "../../../hooks/useUpdateTemplate";

import { useNavigate } from "react-router-dom";
import { invertedBusinessMap } from "../../../utils/listOptions"

import { getTemplateByPurpose } from "../../../services/getTemplateByPurpose";
import { getLegalTextServiceByTemplateId } from "../../../services/getLegalTextService";
import { ConsentResultType } from "../../../types/ConsentContextType";
import templateReducer from "./templateReducer";
import showNotification from "./showNotification";


const findTemplateByCodeName = (templates: NewVersionConsentConfigType[], templateCodeName: string): NewVersionConsentConfigType | undefined => {
  const codeName = templateCodeName.toLowerCase();
  return templates.find((template) => template.name.toLowerCase().includes(codeName));
}

type UpdateConsentProps = {
  dataFetched: NewVersionConsentConfigType[];
  draftData: any;
  business: string;
  country: string;
  purpose: string;
  policyOptions: any;
  loyaltyOptions: any;
};

type Action =
  | { type: 'ADD_TEMPLATE'; payload: string; group: number; country: string; business: string, loyalty?: boolean; policy?: boolean; loyaltyQueryTemplate?: NewVersionConsentConfigType; policyQueryTemplate?: NewVersionConsentConfigType; action: string }
  | {
    type: 'ADD_GROUP_AND_TEMPLATE'; payload: string; country: string; business: string, loyalty?: boolean;
    policy?: boolean;
    loyaltyQueryTemplate?: NewVersionConsentConfigType;
    policyQueryTemplate?: NewVersionConsentConfigType;
    action: string;
  }
  | { type: 'REMOVE_TEMPLATE'; itemPosition: number }
  | {
    type: 'UPDATE_TEMPLATE'; loyalty: boolean; policy: boolean; action: string; loyaltyQueryTemplate?: NewVersionConsentConfigType;
    policyQueryTemplate?: NewVersionConsentConfigType
  };

type TemplateActionType = 'update' | 'add';
type TemplateType = 'loyalty' | 'privacy';

const templateActionAlert = {
  success: {
    update: "actualizado",
    add: "Añadido"
  },
  error: {
    update: "actualizar",
    add: "añadir"
  }
};

const UpdateConsent = ({
  dataFetched,
  draftData,
  business,
  country,
  purpose,
  loyaltyOptions,
  policyOptions,
}: UpdateConsentProps): ReactElement => {
  const { user } = useUser();

  const [templateState, dispatch] = useReducer(templateReducer, {
    templates: [...dataFetched]
  });

  const [switchLoyalty, setSwitchLoyalty] = useState(false);
  const [switchPolicy, setSwitchPolicy] = useState(false);

  const navigate = useNavigate();

  const useFormController = useForm();
  const { mutate } = useUpdateTemplate();

  const mapTemplatesWithExplicitAgree = (templates: NewVersionConsentConfigType[]) => {
    return templates.map((template) => {
      return {
        ...template,
        explicitAgree: template.rules?.explicitAgree,
      };
    });
  }

  const onClickSaveTemplate = async () => {

    // * before save the templates we need to map the templates with the explicitAgree field
    const mappedTemplated = mapTemplatesWithExplicitAgree(templateState.templates);

    await mutate({
      business,
      country,
      purpose,
      templates: mappedTemplated,
      token: user.token,
      version: draftData?.version
    });
  };

  const addElementToGroup = (e: any, groupParam: number) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: "ADD_TEMPLATE",
      group: groupParam,
      country,
      business,
    });
  };

  const addGroupAndElement = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: "ADD_GROUP_AND_TEMPLATE",
      country,
      business,
    });
  };

  const removeElement = (e: any, itemPosition: number, group: number) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: "REMOVE_TEMPLATE",
      itemPosition,
      group,
    });
  };

  const fetchDataAndUpdateTemplate = async (
    itemOrder: number,
    action: string,
    business: string,
    country: string,
    templates: NewVersionConsentConfigType[],
    dispatch: React.Dispatch<Action>,
    templateName?: string
  ) => {

    const LOYALTY_TEMPLATE_CODE_NAME = `${country}${business}_${purpose}_${country}sif_app02317_ecom_reg_tyc_`.toLocaleLowerCase();
    const PRIVACY_TEMPLATE_CODE_NAME = `${country}${business}_${purpose}_${country}fsa_pdp_`.toLowerCase();

    const loyaltyTemplate = findTemplateByCodeName(templates, LOYALTY_TEMPLATE_CODE_NAME);
    const privacyTemplate = findTemplateByCodeName(templates, PRIVACY_TEMPLATE_CODE_NAME);

    const name = itemOrder === 1 ? LOYALTY_TEMPLATE_CODE_NAME : PRIVACY_TEMPLATE_CODE_NAME;

    const cmrPuntosTemplatesLastVersions = await queryCMRPuntosTemplates();
    if (cmrPuntosTemplatesLastVersions.length === 0) {
      return;
    }

    const cmrPuntosTemplate = cmrPuntosTemplatesLastVersions.find(
      (template) => template.itemOrder === itemOrder
    );

    if (!cmrPuntosTemplate) {
      return;
    }

    const { data } = await getLegalTextServiceByTemplateId({
      businessUnit: "LOY",
      country: country,
      templateId: cmrPuntosTemplate.templateId,
      token: user?.token,
    });

    const higherGroup = Math.max(...templates.map((template) => template.group));
    const higherPosition = Math.max(...templates.map((template) => template.position));

    const group = higherGroup + 1;
    const position = higherPosition + 1;

    const newVersionConsentConfigData: NewVersionConsentConfigType = {
      name,
      display_text: cmrPuntosTemplate.displayText ?? "",
      is_mandatory: cmrPuntosTemplate.isMandatory ?? false,
      type: cmrPuntosTemplate.templateType ?? "",
      country: country,
      business: business,
      version: cmrPuntosTemplate.templateVersion ?? "",
      category_type: "PdP-eco",
      group,
      position,
      legal_text: data.legalText ?? "",
      toUpdate: false,
      toCreate: true,
      toDelete: false,
      newElement: true,
      explicitAgree: true,
      rules: {
        explicitAgree: true,
      },
      extra_data: {
        inheritedCmrPuntosTemplate: true,
      },
    };

    
    
    if (loyaltyTemplate) {
      newVersionConsentConfigData.name = PRIVACY_TEMPLATE_CODE_NAME
      newVersionConsentConfigData.group = loyaltyTemplate.group;
      newVersionConsentConfigData.position = Number(loyaltyTemplate.position) + 1;
    }

    if (privacyTemplate) {
      newVersionConsentConfigData.name = LOYALTY_TEMPLATE_CODE_NAME
      newVersionConsentConfigData.group = privacyTemplate.group;
      newVersionConsentConfigData.position = privacyTemplate.position
    }

    let message: string;

    if (action === "update") {
      
      const templateToUpdate = findTemplateByCodeName(templates, name);

      if (templateToUpdate?.version === cmrPuntosTemplate.templateVersion) {
        const templateName = itemOrder === 1 ? "El Reglamento de CMR Puntos" : "La política de privacidad de CMR Puntos";
        message = `${templateName} ya se encuentra en su ultima versión ${cmrPuntosTemplate.templateVersion}`
        showNotification({
          type: "warning",
          message
        })
        return;
      }

      newVersionConsentConfigData.group = templateToUpdate?.group ?? group;
      newVersionConsentConfigData.position = templateToUpdate?.position ?? position;
      newVersionConsentConfigData.name = templateToUpdate?.name ?? "";

      dispatch({
        type: "UPDATE_TEMPLATE",
        loyalty: itemOrder === 1,
        policy: itemOrder === 2,
        loyaltyQueryTemplate: itemOrder === 1 ? newVersionConsentConfigData : undefined,
        policyQueryTemplate: itemOrder === 2 ? newVersionConsentConfigData : undefined,
        action,
      });
      message = templateName === 'loyalty' ? "El Reglamento de CMR Puntos se ha actualizado correctamente" : "La política de privacidad de CMR Puntos se ha actualizado correctamente";

    } else {
      message = templateName === 'loyalty' ? "El Reglamento de CMR Puntos se ha añadiddo correctamente" : "La política de privacidad de CMR Puntos se ha añadiddo correctamente";
      if (loyaltyTemplate && itemOrder === 1 && action==='update') {
        showNotification({
          type: "warning",
          message: "El Reglamento de CMR Puntos ya se encuentra en el proposito"
        })
        return 
      }
  
      if (privacyTemplate && itemOrder === 2) {
        showNotification({
          type: "warning",
          message: "El Reglamento de CMR Puntos ya se encuentra en el proposito"
        })
        return 
      }

      if (!loyaltyTemplate && !privacyTemplate) {
        dispatch({
          type: "ADD_GROUP_AND_TEMPLATE",
          loyalty: itemOrder === 1,
          policy: itemOrder === 2,
          country,
          business,
          loyaltyQueryTemplate: itemOrder === 1 ? newVersionConsentConfigData : undefined,
          policyQueryTemplate: itemOrder === 2 ? newVersionConsentConfigData : undefined,
          action,
          payload: "common",
        });
        showNotification({ type: "success", message: `${message}` })
        return
      }
      
      dispatch({
        type: "ADD_TEMPLATE",
        loyalty: itemOrder === 1,
        policy: itemOrder === 2,
        country,
        business,
        loyaltyQueryTemplate: itemOrder === 1 ? newVersionConsentConfigData : undefined,
        policyQueryTemplate: itemOrder === 2 ? newVersionConsentConfigData : undefined,
        action,
        payload: "common",
        group: loyaltyTemplate?.group || privacyTemplate?.group
      });

 
    }


    showNotification({ type: "success", message: `${message}` })

  };

  const handleTemplateAction = async (
    templateType: TemplateType,
    actionType: TemplateActionType,
    cmrSwitchToggle: boolean
  ) => {
    const templateName = templateType === 'loyalty' ? "El Reglamento de CMR Puntos" : "La política de privacidad de CMR Puntos";
    if (!cmrSwitchToggle) {
      return
    }
    try {
      await fetchDataAndUpdateTemplate(templateType === 'loyalty' ? 1 : 2, actionType, business, country, templateState.templates, dispatch, templateType);
    } catch (error) {
      console.error(error);
      showNotification({
        type: "danger",
        message: `Error al ${templateActionAlert.error[actionType]} ${templateName}`
      })
    }
  };

  const onClickLoyaltyPolicy = (value: boolean, action: TemplateActionType) => handleTemplateAction('loyalty', action, value);
  const onClickPrivacyPolicy = (value: boolean, action: TemplateActionType) => handleTemplateAction('privacy', action, value);

  const queryCMRPuntosTemplates = async (): Promise<ConsentResultType[]> => {
    const cmrPuntosTemplatesLastVersions = await getTemplateByPurpose({
      businessUnit: "LOY",
      country: country,
      purpose: "CMRPuntos",
      token: user?.token
    });
    return cmrPuntosTemplatesLastVersions.data;
  }

  const onChangeGroup = (e: React.ChangeEvent<HTMLSelectElement>, curr: number) => {
    dispatch({
      type: "MOVE_GROUP",
      group: Number(e.target.value),
      currentGroupPosition: curr,
      targetGroupPosition: Number(e.target.value)
    });
  }

  const getGroupsOptionsList = () => {
    return getGroups().map((groupItem: number) => {
      return (
        <option key={groupItem} value={groupItem}>
          Group: {groupItem}
        </option>
      );
    });
  }

  const getTemplateArray = () => {
    return templateState.templates
      .filter((template: NewVersionConsentConfigType) => template.toDelete !== true)
      .sort((a: NewVersionConsentConfigType, b: NewVersionConsentConfigType) => a.position - b.position);
  }

  const getGroups = () => {
    return getTemplateArray()
      .map((template: NewVersionConsentConfigType) => Number(template.group))
      .filter((value: NewVersionConsentConfigType, index: number, array: NewVersionConsentConfigType[]) => array.indexOf(value) === index);
  }

  return (
    <div>
      <Row className="d-flex justify-content-center align-items-center mb-5">
        <Col md={10} lg={10} xs={10} sm={10}>
          <Card className="shadow">
            <h2 className="fw-bold mb-2 text-uppercase text-center">Update Consent</h2>
            <hr />
            <CardBody>
              <div className="row">
                <div className="col mb-2">
                  <Text className="">Country</Text>
                  <Input value={country} onChange={() => null} disabled />
                </div>

                <div className="col mb-2">
                  <Text className="">Business</Text>
                  <Input value={invertedBusinessMap[business] ?? business} onChange={() => null} disabled />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Text className="">Experience</Text>
                  <Input value={purpose} onChange={() => null} disabled />
                </div>
                <div className="col">
                  <div className="row">
                    <Text className="col-sm-8 col-form-label">
                      {loyaltyOptions.labelLoyalty}
                    </Text>
                    <div className="col-sm-2 mt-2">
                      <Switch
                        onChange={(value) => {
                          onClickLoyaltyPolicy(value, loyaltyOptions.loyaltyAction);
                          setSwitchLoyalty(value);
                        }}
                        value={switchLoyalty}
                        size="sm"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Text className="col-sm-8 col-form-label">
                      {policyOptions.labelPolicy}
                    </Text>
                    <div className="col-sm-2 mt-3">
                      <Switch
                        onChange={(value) => {
                          onClickPrivacyPolicy(value, policyOptions.policyAction);
                          setSwitchPolicy(value);
                        }}
                        value={switchPolicy}
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>

      {dataFetched.length > 0 && (
        <>
          <Row className="d-flex justify-content-center align-items-center mb-5">
            <Col md={10} lg={10} xs={10} sm={10}>
              <form>
                {getGroups().map((groupItem: number) => {
                  return (
                    <div key={groupItem}>
                      <Card className="shadow mb-3">
                        <CardHeader>
                          <div className="col-sm-2 ms-4">
                            <select className="form-select" value={groupItem} onChange={(e) => onChangeGroup(e, groupItem)}>
                              {getGroupsOptionsList()}
                            </select>
                          </div>
                        </CardHeader>
                        {getTemplateArray().map((template: NewVersionConsentConfigType, index: number) => {
                          if (Number(template.group) === Number(groupItem)) {
                            return (
                              <div key={index} className="mb-3">
                                <CardBodyCustom>
                                  <ConsentStyledForm className="row">
                                    <UpdateConsentComponent
                                      templates={templateState.templates}
                                      position={template.position}
                                      key={index}
                                      useStateForm={useFormController}
                                      consent={template}
                                      purpose={purpose}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        className="mb-3"
                                        color="danger"
                                        onClick={(e) => removeElement(e, template.position, template.group)}
                                      >
                                        Remove Element
                                      </Button>
                                    </div>
                                  </ConsentStyledForm>
                                </CardBodyCustom>
                              </div>
                            );
                          }
                        })}
                        <div className="d-flex justify-content-end">
                          <Button type="button" onClick={(e) => {
                            addElementToGroup(e, groupItem);
                          }}>
                            Add Element
                          </Button>
                        </div>
                      </Card>
                    </div>
                  );
                })}
                <div>
                  <Button type="button" className="mr-2" onClick={(e) => {
                    addGroupAndElement(e);
                  }}>
                    Add Group
                  </Button>
                </div>
                <div className="d-grid mt-2">
                  <Button
                    className="text-center text-uppercase mt-2"
                    type="button"
                    color="success"
                    onClick={useFormController.handleSubmit(onClickSaveTemplate)}
                  >
                    Save Templates
                  </Button>
                  <Button
                    className="text-center text-uppercase mt-2"
                    color="bordered"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default UpdateConsent;
