import React, { ReactElement } from "react";
import { CKEditor } from "ckeditor4-react";

type EditorProps = {
  setValue: (value: string) => void;
  data: string;
  isReadOnly?: boolean;
  handleOnDestroy?: (e: any) => void;
};


const Editor = ({ data, isReadOnly = false, setValue, handleOnDestroy }: EditorProps): ReactElement => {
  return (
    <div>
      <CKEditor
        readOnly={isReadOnly}
        style={{ backgroundColor: "white" }}
        initData={data}
        config={{
          toolbar: [
            {
              name: "clipboard",
              groups: ["clipboard", "undo"],
              items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"]
            },
            "/",
            {
              name: "basicstyles",
              groups: ["basicstyles", "cleanup"],
              items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"]
            },
            {
              name: "paragraph",
              groups: ["list", "indent", "blocks", "align", "bidi"],
              items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote"]
            },
            { name: "styles", items: ["Styles", "Format"] },
            { name: "tables", items: ["Table"] }
          ],
          width: "100%",
          height: "500px",
          versionCheck: false
        }}
        onChange={(e) => {
          setValue(e.editor.getData());
        }}
        onDestroy={handleOnDestroy}
      />
    </div>
  );
};

export default Editor;
