import React from "react";
import { Email } from "../../types";
import { Text } from "@jarvis-catalyst/ui-components";

type Props = {
  emails?: Email[];
};

const EmailsTab = ({ emails }: Props): JSX.Element => {
  return (
    <>
      {emails?.map((email, index) => {
        return (
          <div key={index}>
            <div className="row">
              <div className="col">
                <Text className="">Email</Text>
                <input type="text" className="form-control" disabled value={email.email} />
              </div>
              <div className="col">
                <Text className="">Verified Email</Text>
                <input type="text" className="form-control" disabled value={String(email.verifiedEmail)} />
              </div>
            </div>
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default EmailsTab;
