import React, { ReactElement } from "react";
import DeleteKeycloakForm from "../../../components/Form/deleteKeycloakForm";

const DeleteKeycloak = (): ReactElement => {
  return (
    <>
      <DeleteKeycloakForm action="deleteKeycloak" title="Delete Keycloak User" />
    </>
  );
};

export default DeleteKeycloak;
