import { NewVersionConsentConfigType } from "../../../types/newVersionConsentConfig";

type Template = NewVersionConsentConfigType & {
  group: number;
  country: string;
  business: string;
  position: number;
  name: string;
  display_text: string;
  is_mandatory: boolean;
  type: string;
  version: string;
  category_type: string;
  legal_text: string;
  newElement: boolean;
  toCreate: boolean;
  explicitAgree: boolean;
  rules: {
    explicitAgree: boolean;
  };
  id?: number;
  toDelete?: boolean;
  toUpdatePosition: boolean;
}

type Action = {
  type: string;
  group?: number;
  country?: string;
  business?: string;
  loyalty?: boolean;
  policy?: boolean;
  loyaltyQueryTemplate?: Template;
  policyQueryTemplate?: Template;
  itemPosition?: number;
  currentGroupPosition?: number;
  targetGroupPosition?: number;
}

type TemplateState = {
  templates: Template[];
}

const findTemplateByCodeName = (templates: Template[], templateCodeName: string) => {
  return templates.find((template) => template.name === templateCodeName);
}

// Create a new template object with the provided values
const createNewTemplate = (group: number, position: number, country: string, business: string): Template => {
  return {
    group,
    country,
    business,
    position,
    name: "",
    display_text: "",
    is_mandatory: false,
    type: "",
    version: "001",
    category_type: "Seleccione una Categoría OPT-IN",
    legal_text: "",
    newElement: true,
    toCreate: true,
    explicitAgree: false,
    rules: {
      explicitAgree: false
    },
    toUpdatePosition: false
  };
};

// order the templates by group and position
const sortTemplateByGroupAndPosition = (templates: Template[]) => {
  return templates.sort((a, b) => {
    if (a.group === b.group) {
      return a.position - b.position;
    }
    return a.group - b.group;
  });
}

// Add a new template to the state's templates array
const addTemplate = (state: TemplateState, action: Action) => {
  const { group, country = "CL", business = "FAL" } = action;
  const templates = [...state.templates].filter((template: Template) => !template.toDelete)

  const templatesByCurrentGriup = templates.filter((template: Template) => Number(template.group) === Number(group));

  const maxPosition = (Math.max(...templatesByCurrentGriup.map((template: Template) => template.position)) + 1);

  let newTemplate;
  
  newTemplate = createNewTemplate(Number(group), maxPosition, country, business);
  
  if (action.loyalty || action.policy) {
    newTemplate = action.loyalty ? action.loyaltyQueryTemplate : action.policyQueryTemplate;
    newTemplate?.position
  }

  if (!action.loyalty || !action.policy) {
    newTemplate = action.loyalty ? action.loyaltyQueryTemplate : (action.policyQueryTemplate || newTemplate);
  }


  const greatherEqualPositionTemplates = templates.filter((template: Template) => Number(template.position) >= Number(newTemplate.position));

  const updatedTemplates = greatherEqualPositionTemplates.map((template) => {
    return {
      ...template,
      position: (Number(template.position) + 1)
    };
  });

  const remainingTemplates = templates.filter((template: Template) => Number(template.position) < Number(newTemplate.position));

  const renderTemplates = sortTemplateByGroupAndPosition([...remainingTemplates, ...updatedTemplates, newTemplate]);

  return {
    ...state,
    templates: [...renderTemplates]
  };
};

// * Add a new group and template to the state's templates array
const addGroupAndTemplate = (state: TemplateState, action: Action) => {
  const { country = "CL", business = "FAL" } = action;
  const templates = [...state.templates];

  const filteredTemplates = templates.filter((template: Template) => !template.toDelete);
  const maxGroup = (Math.max(
    ...filteredTemplates.map((template: Template) => template.group),0) + 1);
  const maxPosition = (Math.max(...filteredTemplates.map((template: Template) => template.position),0) + 1);

  const newTemplate = createNewTemplate(Number(maxGroup), Number(maxPosition), country, business);

  const array = [...filteredTemplates];
  if (action.loyalty || action.policy) {
    const queryTemplate = action.loyalty ? action.loyaltyQueryTemplate : action.policyQueryTemplate;
    queryTemplate.group = maxGroup;
    queryTemplate.position = maxPosition;

    array.push({
      ...newTemplate,
      ...queryTemplate
    });

    return {
      ...state,
      templates: array,
    };

  } else {
    array.push({ ...newTemplate });
    return {
      ...state,
      templates: array
    };
  }
};

// Helper function to remap group values
const remapGroupValues = (groupedTemplates: Partial<Record<number, Template[]>> ) => {
  let remappedTemplates: Template[] = [];
  Object.keys(groupedTemplates).forEach((key, index) => {
    const groupIndex = index + 1;
    const groupTemplates = groupedTemplates[Number(key)]?.map(template => ({
      ...template,
      group: groupIndex
    }));
    if (groupTemplates) {
      remappedTemplates = [...remappedTemplates, ...groupTemplates];
    }
  });
  return remappedTemplates;
};


// * Remove a template from the state's templates array
const removeTemplate = (state: TemplateState, action: Action) => {

  const templates = [...state.templates.filter((template: Template) => !template.toDelete)]

  const remainingTemplates = templates
    .filter((template: Template) => Number(template.position) !== Number(action.itemPosition))
    .filter((template: Template) => !template.toDelete)
  const templateToDelete = templates.find((template: Template) => Number(template.position) === Number(action.itemPosition));
  const templateFromCurrentGroup = templates.filter((template: Template) => Number(template.group) === Number(templateToDelete?.group));

  if (templateToDelete?.newElement) {
    let updatedRemainingTemplates = sortTemplateByGroupAndPosition(remainingTemplates)
    updatedRemainingTemplates = remainingTemplates.map((template, i) => ({ ...template, position: i + 1, toUpdatePosition: false }));
    
    if (templateFromCurrentGroup.length <= 1 ) {
      const groupedTemplates = Object.groupBy(updatedRemainingTemplates, template => template.group);
      updatedRemainingTemplates = remapGroupValues(groupedTemplates);

    }
    const ordered = sortTemplateByGroupAndPosition([...updatedRemainingTemplates]);
    return {
      ...state,
      templates: [...ordered],
    };
  }

  if (templateToDelete?.id) {

    const updatedTemplateToDelete= { ...templateToDelete, toDelete: true};
    let updatedRemainingTemplates = sortTemplateByGroupAndPosition(remainingTemplates)
    updatedRemainingTemplates = remainingTemplates.map((template, i) => ({ ...template, position: i + 1, toUpdatePosition: true }));

    if (templateFromCurrentGroup.length <= 1) {
      const groupedTemplates = Object.groupBy(updatedRemainingTemplates, template => template.group);
      updatedRemainingTemplates = remapGroupValues(groupedTemplates);
    }

    const ordered = sortTemplateByGroupAndPosition([...updatedRemainingTemplates, updatedTemplateToDelete]);

    return {
      ...state,
      templates: [...ordered],
    };
  }
};

// * Update a template in the state's templates array
const updateTemplate = (state: TemplateState, action: Action) => {
  const templates = [...state.templates];
  const searchText = action.loyaltyQueryTemplate?.name || action.policyQueryTemplate?.name || '';
  const queryTemplate = action.loyalty ? action.loyaltyQueryTemplate : action.policy ? action.policyQueryTemplate : null;
  const existingTemplate = findTemplateByCodeName(templates, searchText);

  const newArray = templates.filter((template: Template) => template.position !== existingTemplate?.position);
  return {
    ...state,
    templates: [...newArray, queryTemplate]
  };
};

const moveGroup = (state: TemplateState, action: Action) => {

  const current = action.currentGroupPosition;
  const target = action.targetGroupPosition;
  const templates = [...state.templates];

  const templateFromTargetGroup = templates
    .filter((template) => Number(template.group) === target)
    .map(template => ({ ...template, group: current || 0 }))

  const templateFromCurrentGroup = templates
    .filter((template) => Number(template.group) === current)
    .map(template => ({ ...template, group: target || 0 }))

  const remainingTemplates = templates.filter((template) => Number(template.group) !== current && Number(template.group) !== target);

  const ordered = [
    ...templateFromCurrentGroup,
    ...templateFromTargetGroup,
    ...remainingTemplates
  ].sort((a, b) => a.group - b.group)

  ordered.forEach((item, i) => {
    item.position = i + 1;
  });

  return {
    ...state,
    templates: [...ordered]
  };
}

// * The main reducer function that handles different action types
const templateReducer = (state: TemplateState, action: Action): any => {
  try {
    switch (action.type) {
      case "ADD_TEMPLATE":
        return addTemplate(state, action);
      case "ADD_GROUP_AND_TEMPLATE":
        return addGroupAndTemplate(state, action);
      case "REMOVE_TEMPLATE":
        return removeTemplate(state, action);
      case "UPDATE_TEMPLATE":
        return updateTemplate(state, action);
      case "MOVE_GROUP":
        return moveGroup(state, action);
      default:
        return state;
    }
  } catch (error) {
    console.log(error);
    throw new Error("error");
  }
};

export default templateReducer;