import { PERMISSION } from "../constants";

const {
  JARVIS_USER,
  BLOCKLIST_DOCUMENT,
  DELETE_ACCOUNT,
  DELETE_CUSTOMER,
  CUSTOMER_IDENTITY,
  CUSTOMER_ACCOUNT,
  LOCK_PASSWORD,
  UNLOCK_PASSWORD,
  CONSENT_MANTAINER,
  AUDIT_LOG
} = PERMISSION;

export const optionsList = [
  {
    title: "Delete Account (Mandato Apple)",
    url: "/delete-account",
    enabled: false,
    validPermissions: [DELETE_ACCOUNT]
  },
  {
    title: "Delete Customer (CIAM_ID)",
    url: "/delete-user",
    enabled: true,
    validPermissions: [DELETE_CUSTOMER]
  },
  {
    title: "Validate User",
    url: "/validateConsent",
    enabled: true,
    validPermissions: [CUSTOMER_IDENTITY, CUSTOMER_ACCOUNT]
  },
  {
    title: "Lock Password",
    url: "/lockPassword",
    enabled: true,
    validPermissions: [LOCK_PASSWORD]
  },
  {
    title: "Unlock Password",
    url: "/unlockPassword",
    enabled: true,
    validPermissions: [UNLOCK_PASSWORD]
  },
  {
    title: "Bloqueo y Desbloqueo de Clientes",
    url: "/user-management",
    enabled: true,
    validPermissions: [BLOCKLIST_DOCUMENT]
  },
  {
    title: "Mantenedor de Consentimientos",
    url: "/consent",
    enabled: true,
    validPermissions: [CONSENT_MANTAINER]
  },
  {
    title: "Audit Log",
    url: "/audit",
    enabled: true,
    validPermissions: [JARVIS_USER, AUDIT_LOG]
  }
];
