import React, { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormType } from "../../types";
import { useConsent } from "../../hooks/useConsent";
import { useUser } from "../../hooks/useUser";
import { Col, Row, Tab } from "@jarvis-catalyst/ui-components";
import LoadingComponent from "../../components/Loading";
import ProfileTab from "../../components/ValidateUserTabs/ProfileTab";
import ConsentTab from "../../components/ValidateUserTabs/ConsentTab";
import ErrorComponent from "../../components/Error";
import { ButtonBackCustom } from "../../styled-components/Buttons";
import { useValidateForm } from "../../hooks/useValidateForm";

type LocationState = {
  form: FormType;
};

const ProfileConsent = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { setForm, form } = useValidateForm();
  const { consentQuery, setFetched, getConsents, getProfile } = useConsent({
    currentUser: user,
    email: form.email,
    serviceProvider: form.serviceProvider,
    nroDocumento: form.nroDocumento,
    tipoDocumento: form.tipoDocumento,
    isB2B: form.isB2B
  });

  const { error, isFetching, isLoading, isError } = consentQuery;

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }

    const { form } = location.state as LocationState;
    const newForm = {
      ...form,
      currentUser: user
    };

    setForm(newForm);
    setFetched(true);
  }, [location]);

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={12} lg={12} xs={12} sm={12}>
          {!isError ? (
            <>
              <div
                style={{
                  maxWidth: "150px"
                }}
              >
                <div className="mt-2 mb-2">
                  <ButtonBackCustom onClick={() => navigate("/validateConsent")}>Back</ButtonBackCustom>
                </div>
              </div>
              <Tab
                pages={[
                  {
                    title: "Profile",
                    key: "profile",
                    body: isLoading || isFetching ? <LoadingComponent /> : <ProfileTab data={getProfile} />
                  },
                  {
                    title: "Consent",
                    key: "consent",
                    body: isLoading || isFetching ? <LoadingComponent /> : <ConsentTab data={getConsents} />
                  }
                ]}
              />
            </>
          ) : (
            <div>
              <ErrorComponent
                isError={404}
                title={error.message ?? `An error occurred while loading the data`}
                redirectTo="/validateConsent"
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProfileConsent;
